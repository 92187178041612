import { Component, ViewEncapsulation } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 's2a-cover-image',
  templateUrl: './cover-image.component.html',
  styleUrls: ['./cover-image.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CoverImageComponent {
  imageUrl: string | undefined;

  constructor() {
    this.imageUrl = environment.imageUrl;
  }
}
