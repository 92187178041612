/* eslint-disable max-len */
import { switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';

import { S2ANotificationService, untilDestroyed } from '@s2a-core/ng-core';
import { AuthService, LanguageKey, Translation } from '@s2a/core';

import { ComponentLoaderService, ComponentMap, SessionService, showLanguageDialog } from '~ng-shell';

import { environment } from '../environments/environment';
import { ForgotPasswordPayLoad, LoginPayLoad, RecentUser, UserAuthResponse, UserSession } from './model';
import { LoginApiService } from './services/login-api.service';
import { RouterService } from './services/router.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 's2a-login-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('languageDialog', { static: true })
  languageDialog: TemplateRef<any>;

  currentLanguageKey: LanguageKey;

  recentUsers: RecentUser[] = [];
  apiError = '';
  accountText = '';
  showForgotPasswordLink = false;
  showForgotPasswordPage = false;
  showKwBtn: boolean;
  private untilDestroyed = untilDestroyed();
  translationText: { [key: string]: string };

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private translateService: Translation,
    private sessionService: SessionService,
    private routerService: RouterService,
    private meta: Meta,
    private notificationService: S2ANotificationService,
    private loginApiService: LoginApiService,
    private componentLoaderService: ComponentLoaderService,
    private route: ActivatedRoute,
  ) {
    this.currentLanguageKey = this.translateService.getCurrentLanguage();
    this.showKwBtn = false;
    this.translationText = {
      accountText: this.translateService.instant('components.login.account'),
      usernameText: this.translateService.instant('components.login.username'),
      passwordText: this.translateService.instant('components.login.password'),

      headerText: this.translateService.instant('components.login.login'),
      buttonText: this.translateService.instant('components.login.kw_login'),
      forgotPasswordText: this.translateService.instant('components.login.forgot_password'),
      recentUserText: this.translateService.instant('components.login.recent_users'),
      loginDividerText: this.translateService.instant('components.login.login_separator'),

      resetPasswordRequestText: this.translateService.instant('components.login.request_password_reset'),
      backToLoginText: this.translateService.instant('components.login.back_to_login'),
    };
  }

  ngOnInit(): void {
    this.recentUsers = this.loginApiService.recentUsers;
    this.translateService.onLangChange.pipe(this.untilDestroyed()).subscribe(() => {
      this.translationText.accountText = this.translateService.instant('components.login.account');
      this.translationText.usernameText = this.translateService.instant('components.login.username');
      this.translationText.passwordText = this.translateService.instant('components.login.password');
      this.translationText.buttonText = this.translateService.instant('components.login.kw_login');
      this.translationText.headerText = this.translateService.instant('components.login.login');
      this.translationText.recentUserText = this.translateService.instant('components.login.recent_users');
      this.translationText.loginDividerText = this.translateService.instant('components.login.login_separator');

      this.translationText.forgotPasswordText = this.translateService.instant('components.login.forgot_password');
      this.translationText.resetPasswordRequestText = this.translateService.instant(
        'components.login.request_password_reset',
      );
      this.translationText.backToLoginText = this.translateService.instant('components.login.back_to_login');
    });
    this.route.queryParamMap.subscribe((params) => {
      this.accountText = params.get('accountId') || '';
    });
    // Disable indexing non-production environment
    if (!environment.production) {
      this.meta.addTags([
        // To prevent most search engines from indexing a page on your site
        { name: 'robots', content: 'noindex' },
        // To prevent only Google web crawlers from indexing a page
        { name: 'googlebot', content: 'noindex' },
      ]);
    }
    this.componentLoaderService.loadComponent([ComponentMap.Button]);
  }

  /**
   * The function is called when user click on login button to login into share 2 act environment
   *
   */
  loginWithS2A(loginPayLoad: LoginPayLoad): void {
    this.loginApiService
      .authenticate(loginPayLoad)
      .pipe(
        this.untilDestroyed(),
        switchMap((authResponse: UserAuthResponse) => this.loginApiService.userWithRights(authResponse)),
      )
      .subscribe(
        (userInfo) => {
          const sessionId = uuidv4();
          const userSession = { ...userInfo, sessionId };
          this.sessionService.setSession({ ...userSession } as UserSession);
          this.authService.isLoggedIn = true;
          this.routerService.redirectToApp();
          this.apiError = '';
        },
        (errorObject) => {
          this.showForgotPasswordLink = true;
          if (errorObject.error.code === 'error.conflict') {
            this.apiError = this.translateService.instant('components.login.errors.inactive');
          } else {
            this.apiError = this.translateService.instant('components.login.errors.invalid');
          }
        },
      );
  }

  /**
   * The function is called when reset password response has arrived
   *
   */
  forgotS2APasswordRequested(forgotPasswordPayload: ForgotPasswordPayLoad): void {
    this.apiError = '';
    this.loginApiService
      .resetPassword(forgotPasswordPayload.account, forgotPasswordPayload.username)
      .pipe(this.untilDestroyed())
      .subscribe(
        (_) => {
          this.notificationService.success(
            this.translateService.instant('components.login.request_password_reset_success'),
          );
          this.showForgotPasswordPage = false;
          this.showForgotPasswordLink = false;
        },
        (_) => {
          this.notificationService.error(
            this.translateService.instant('components.login.request_password_reset_failed'),
          );
        },
      );
  }

  changeLanguage(): void {
    showLanguageDialog(this.dialog, this.languageDialog)
      .pipe(this.untilDestroyed())
      .subscribe((language: LanguageKey) => {
        this.currentLanguageKey = language;
        this.translateService.switchLanguage(language).pipe(this.untilDestroyed()).subscribe();
      });
  }
}
