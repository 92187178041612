<div style="width: 100%" class="login-wrapper">
  <div *ngIf="!showForgotPasswordPage; else forgotPasswordTemplate">
    <form class="login-controls" [formGroup]="loginForm">
      <p class="heading">{{ translationText?.headerText }}</p>
      <ng-container *ngIf="showKronesWorldButton">
        <button type="button" mat-button class="matter-button-contained krones-world" (click)="redirectToKronesWorld()">
          {{ translationText.buttonText }}&nbsp;
          <img [src]="environment.iconUrl + 'kronesWorldLogo_dark.svg'" alt="krones.worldLogo.svg-logo" />
        </button>
        <p class="separator">
          <span>{{ translationText.loginDividerText }}</span>
        </p>
      </ng-container>
      <div class="recent-users" *ngIf="recentUsers.length">
        <div>
          <p class="matter-body1">{{ translationText?.recentUserText }}</p>
        </div>
        <div class="s2a-flex">
          <div *ngFor="let recentUser of recentUsers" class="circle" (click)="selectLastUser(recentUser)">
            {{ getUsernameLetter(recentUser) }}
          </div>
        </div>
      </div>
      <div>
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>{{ translationText?.accountText }}</mat-label>
          <input matInput formControlName="account" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>{{ translationText?.usernameText }}</mat-label>
          <input matInput formControlName="username" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>{{ translationText?.passwordText }}</mat-label>
          <input type="password" matInput formControlName="password" />
        </mat-form-field>
      </div>

      <div class="login-field">
        <div class="error-text" [innerHTML]="mailto"></div>
      </div>
      <div (click)="toggleForgotPasswordScreen()" [ngClass]="{ hide: !showForgotPasswordLink }" class="login-field">
        <div class="error-text color-primary cur-ptr">
          {{ translationText.forgotPasswordText }}
        </div>
      </div>
      <div class="login-actions">
        <button type="submit" mat-raised-button (click)="loginUsingS2A()" [disabled]="!loginForm.valid">
          {{ translationText?.buttonText }}&nbsp;
          <img [src]="environment.imageUrl + 'img/brand.svg'" alt="share2act-logo" />
        </button>
      </div>
    </form>
  </div>

  <ng-template #forgotPasswordTemplate>
    <form class="login-controls" [formGroup]="forgotPasswordForm">
      <div>
        <div class="error-text color-primary cur-ptr text-align-left" (click)="toggleForgotPasswordScreen()">
          <&nbsp;{{ translationText.backToLoginText }}
        </div>
        <p class="heading">{{ translationText.forgotPasswordText }}</p>
        <div>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{ translationText?.accountText }}</mat-label>
            <input matInput formControlName="account" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{ translationText?.usernameText }}</mat-label>
            <input matInput formControlName="username" />
          </mat-form-field>
        </div>
        <div class="login-actions">
          <button
            type="submit"
            mat-raised-button
            (click)="resetPasswordSubmit()"
            [disabled]="!forgotPasswordForm.valid"
          >
            {{ translationText.resetPasswordRequestText }}
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</div>
