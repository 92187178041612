import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import {
  LocalStorageService,
  LOCALSTORAGE_ACCESS_TOKEN,
  LOCALSTORAGE_AUTH_TOKEN,
  LOCALSTORAGE_REFRESH_TOKEN,
} from '@s2a/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const getCookie = (name: string): string => {
  name = `${name}=`;
  const ca = document.cookie.split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

// App Bootstrapping
const bootstrapApp = () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
};

const storage = new LocalStorageService();

if (
  storage.getItem(LOCALSTORAGE_AUTH_TOKEN) &&
  storage.getItem(LOCALSTORAGE_REFRESH_TOKEN) &&
  storage.getItem(LOCALSTORAGE_ACCESS_TOKEN)
) {
  const lastApp = getCookie('s2a-last-used-app');
  window.location.href = environment.domainUrl.concat(`${lastApp ? lastApp : ''}`);
} else {
  bootstrapApp();
}
