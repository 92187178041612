import { Inject, Injectable } from '@angular/core';

import { CookieService, S2A_LAST_USED_APP, WindowToken } from '~ng-shell';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private readonly redirectPrefix = '?redirect=';

  constructor(
    @Inject(WindowToken) private window: Window,
    private cookieService: CookieService,
  ) {}

  redirectToApp(): void {
    return this.hasRedirectUri() ? this.openRedirectUri() : this.openRecentApp();
  }

  private hasRedirectUri(): boolean {
    return this.window.location.search.includes(this.redirectPrefix);
  }

  private openRedirectUri(): void {
    const redirectUri = decodeURIComponent(this.window.location.search.replace(this.redirectPrefix, ''));
    this.window.location.href = environment.domainUrl.concat(redirectUri);
  }

  private openRecentApp(): void {
    const lastApp = this.cookieService.get(S2A_LAST_USED_APP);
    this.window.location.href = environment.domainUrl.concat(`${lastApp ? lastApp : ''}`);
  }
}
