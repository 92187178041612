<div class="other-info">
  <div class="policies">
    <!-- Legal -->
    <div class="legal-container" *ngIf="!isChinaRegion; else chinaContent">
      <div>
        <a class="brand-icon" target="_blank" [href]="getUrl('imprint')">Imprint</a>
        <span>|</span>
      </div>
      <a class="brand-icon" target="_blank" [href]="getUrl('privacy-policy')">Privacy Policy</a>
    </div>
    <!-- Brand Link/Logo -->
    <div>
      <a target="_blank" href="https://digital-services.krones.com/">
        <mat-icon class="krones-logo" svgIcon="kronesWorldLogo_light"></mat-icon>
      </a>
    </div>
  </div>
</div>
<ng-template #chinaContent>
  <div class="legal-container">
    <div>
      <a
        class="brand-icon"
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32058502010628"
      >
        <img class="icp-icon" src="{{ imageUrl }}img/icp_cn_gov.png" />
        苏公网安备 32058502010628号</a
      >
      <span>|</span>
    </div>
    <a class="brand-icon" target="_blank" href="https://beian.miit.gov.cn/"> 苏ICP备18049390号-1</a>
  </div>
</ng-template>
