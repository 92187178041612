/* eslint-disable class-methods-use-this */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';
import { ForgotPasswordPayLoad, LoginPayLoad, LoginTranslationText, RecentUser } from '../../model';

/* eslint-disable max-len */
export const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;

@Component({
  selector: 's2a-pure-login',
  templateUrl: './pure-login.component.html',
  styleUrls: ['./pure-login.component.scss'],
})
export class PureLoginComponent {
  @Input() translationText: LoginTranslationText;
  @Input() showForgotPasswordLink = false;
  @Input() showKronesWorldButton = false;
  @Input() apiError = '';
  @Input() recentUsers: RecentUser[] = [];
  @Input() showForgotPasswordPage = false;
  @Input() set accountText(account: string) {
    if (account) {
      this.loginForm.patchValue({ account });
    }
  }

  @Output() showForgotPasswordLinkChange: EventEmitter<boolean> = new EventEmitter();
  @Output() apiErrorChange: EventEmitter<string> = new EventEmitter();
  @Output() showForgotPasswordPageChange: EventEmitter<boolean> = new EventEmitter();
  @Output() loginS2A: EventEmitter<LoginPayLoad> = new EventEmitter();
  @Output() loginKW: EventEmitter<undefined> = new EventEmitter();
  @Output() forgotS2APassword: EventEmitter<ForgotPasswordPayLoad> = new EventEmitter();

  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  environment = environment;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.loginForm = fb.group({
      account: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.forgotPasswordForm = fb.group({
      account: ['', Validators.required],
      username: ['', Validators.required],
    });
  }

  get mailto(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.apiError.replace(
        EMAIL_REGEX,
        `<a style="
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        text-decoration: none;
        color: var(--warn900) !important;
      " href="mailto:$&">$&</a>`,
      ),
    );
  }

  getUsernameLetter(user: RecentUser): string {
    return user.firstname.charAt(0).toUpperCase().concat(user.lastname.charAt(0).toUpperCase());
  }

  loginUsingS2A(): void {
    this.loginS2A.emit(this.loginForm.value as LoginPayLoad);
  }

  redirectToKronesWorld(): void {
    this.loginKW.emit();
  }

  resetPasswordSubmit(): void {
    this.forgotS2APassword.emit(this.forgotPasswordForm.value as ForgotPasswordPayLoad);
  }

  toggleForgotPasswordScreen(): void {
    this.showForgotPasswordPageChange.emit(!this.showForgotPasswordPage);
    if (!this.showForgotPasswordPage) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.forgotPasswordForm.patchValue(this.loginForm.value);
    } else {
      this.showForgotPasswordLinkChange.emit(false);
      this.apiErrorChange.emit('');
    }
  }

  selectLastUser(user: RecentUser): void {
    this.loginForm.patchValue(user);
  }
}
