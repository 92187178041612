/* eslint-disable class-methods-use-this */
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { S2AHttpClient } from '@s2a-core/ng-core';
import { LOCALSTORAGE_LAST_USERS } from '@s2a/core';

import { LoggedInUser, LoginPayLoad, RecentUser, UserAuthResponse, UserSession } from '../model';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  constructor(private s2aHttpClient: S2AHttpClient) {}

  get recentUsers(): RecentUser[] {
    try {
      return JSON.parse(localStorage.getItem(LOCALSTORAGE_LAST_USERS) || '[]') as RecentUser[];
    } catch (e) {
      return [];
    }
  }

  authenticate(payload: LoginPayLoad): Observable<any> {
    return this.s2aHttpClient.post('auth/login', payload);
  }

  userWithRights(authResponse: UserAuthResponse): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${authResponse.IdToken}`);

    return combineLatest([
      this.s2aHttpClient.get<LoggedInUser>(`users/${authResponse.userId}`, {
        headers,
      }),
      this.s2aHttpClient.get<string[]>('users/me/global-rights', { headers }),
    ]).pipe(
      map(([user, globalRights]) => {
        this.setRecentUsers({
          ...authResponse,
          globalRights,
          ...user,
        } as UserSession);

        return { ...authResponse, globalRights, ...user };
      }),
    );
  }

  setRecentUsers(userInfo: UserSession): void {
    let recentUsers = JSON.parse(localStorage.getItem(LOCALSTORAGE_LAST_USERS) || '[]');
    recentUsers = recentUsers.filter((record: any) => {
      if (record.account === userInfo.account && record.username === userInfo.username) {
        return false;
      }

      return true;
    });
    const publicInfo: RecentUser = {
      account: userInfo.account,
      username: userInfo.username,
      firstname: userInfo.firstname,
      lastname: userInfo.lastname,
    };
    recentUsers = [publicInfo, ...recentUsers.slice(0, 4)];
    localStorage.setItem(LOCALSTORAGE_LAST_USERS, JSON.stringify(recentUsers));
  }

  resetPassword(account: string, username: string): Observable<any> {
    return this.s2aHttpClient.post(`account/${account}/users/${username}/resetpassword`, {});
  }
}
