<div class="wrapper">
  <div class="page-container">
    <s2a-cover-image class="cover-image-container"></s2a-cover-image>
    <div class="side-container">
      <div>
        <div class="change-language">
          <button class="language-button" mat-button color="primary" (click)="changeLanguage()">
            <mat-icon>translate</mat-icon>
            <span translate class="text-wrapper"> components.login.change_language </span>
          </button>
        </div>
      </div>
      <div class="form-container">
        <s2a-pure-login
          [accountText]="accountText"
          [recentUsers]="recentUsers"
          [(showForgotPasswordPage)]="showForgotPasswordPage"
          [(showForgotPasswordLink)]="showForgotPasswordLink"
          [(apiError)]="apiError"
          (loginS2A)="loginWithS2A($event)"
          (forgotS2APassword)="forgotS2APasswordRequested($event)"
          [translationText]="translationText"
          [showKronesWorldButton]="showKwBtn"
        ></s2a-pure-login>
      </div>
      <s2a-footer></s2a-footer>
    </div>
  </div>
</div>
<ng-template #languageDialog>
  <s2a-language-selector [selectedLanguageKey]="currentLanguageKey"></s2a-language-selector>
</ng-template>
