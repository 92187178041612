import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AssetRegistry, IconRegistry, TranslationModule } from '@s2a-core/ng-core';
import { AssetLoaderService, AuthService, Environment, IconClientService } from '@s2a/core';

import { NgShellModule } from '~ng-shell';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { MaterialModule } from './app.material.module';
import { CoverImageComponent } from './components/cover-image/cover-image.component';
import { FooterComponent } from './components/footer/footer.component';
import { PureLoginComponent } from './components/pure-login/pure-login.component';

@NgModule({
  declarations: [AppComponent, CoverImageComponent, FooterComponent, PureLoginComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserAnimationsModule,
    MaterialModule,
    RouterModule.forRoot([], {}),
    NgShellModule.forRoot(environment as Environment),
    TranslationModule.forRoot(environment),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: Environment,
      useValue: environment,
    },
    IconClientService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: IconRegistry,
      deps: [IconClientService, MatIconRegistry, DomSanitizer, Environment],
    },
    { provide: AuthService, useValue: new AuthService(environment) },
    AssetLoaderService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: AssetRegistry,
      deps: [AssetLoaderService, Environment],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
